<template>
  <div>
    <b-modal
      id="modal-form"
      centered
      hide-footer
      :title="modalTitle"
    >
      <b-card-text>
        <b-alert
          v-if="errors.length > 0"
          variant="danger"
          show
        >
          <div class="alert-body">
            <p
              v-for="error in errors"
              :key="error"
            >
              {{ error }}
            </p>
          </div>
        </b-alert>
        <b-form-group
          label="Parent Category"
        >
          <b-form-select
            v-model="form.parent_id"
            :options="parentList"
          />
        </b-form-group>
        <b-form-group
          label="Name"
        >
          <b-form-input
            v-model="form.name"
            placeholder="Enter Name"
          />
        </b-form-group>
      </b-card-text>
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="handleSave()"
        >
          <feather-icon
            icon="CheckIcon"
            class="mr-50"
          />
          <span>Simpan</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BAlert, BModal, VBModal, BCardText, BButton, BFormInput, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BAlert,
    BModal,
    BCardText,
    BButton,
    BFormInput,
    BFormGroup,
    BFormSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    modalTitle: {
      type: String,
      default: 'Tambah Kategori',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    parentList: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      errors: [],
    }
  },
  methods: {
    async handleSave() {
      try {
        if (this.form.id) {
          await this.$http.put(`/v1/category/${this.form.id}`, this.form)
        } else {
          await this.$http.post('/v1/category', this.form)
        }

        this.$bvModal.hide('modal-form')
        this.$emit('success')
        this.$emit('refresh')
      } catch (error) {
        this.errors = error.response.data.errors
        this.$emit('error', error.response.data.messages)
      }
    },
  },
}
</script>
